import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet'
import Img from "gatsby-image";
import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ContactForm from "../components/ContactForm";
import OurValue from "../components/OurValue";
import innovapost from "../images/innovapost.webp";
import worldvision from "../images/worldvision.png";
import nrt from "../images/nrt.png";
import community from "../images/community.png";
import wordjack from "../images/wordjack.png";
import xcg from "../images/xcg.png";
import fortinet from "../images/fortinet.png";
import tca from "../images/tca.webp";
import dh from "../images/dh.png";
import nwplus from "../images/nwplus.webp";
import h1 from "../images/h1.png";
// import xiaoju from "../images/xiaoju.png";
import alpremium from "../images/alpremium.png";
import immuniweb from "../images/immuniweb.webp";
import huawei from "../images/huawei.webp";
import docker from "../images/docker.webp";
import housecall from "../images/housecall.webp";
import nicejob from "../images/nicejob.webp";
import armor from "../images/armor.png";
import arcsight from "../images/arcsight.svg";
import enterasys from "../images/enterasys.webp";
import altor from "../images/altor.svg";
import art from "../images/art.svg";
import KeepInformed from "../components/KeepInformed";
import ImageMeta from '../components/common/meta/ImageMeta'
import config from '../utils/siteConfig'
import coverImage from '../images/security.png'

const Contact = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>{config.siteTitleMeta}</title>
                <meta name="description" content={config.siteDescriptionMeta} />
                <link rel="canonical" href={config.siteUrl + "/contact"} />

                <meta property="og:site_name" content={config.siteName} />
                <meta property="og:type" content="page" />
                <meta property="og:title"
                    content={config.siteTitleMeta}
                />
                <meta property="og:description"
                    content={config.siteDescriptionMeta}
                />
                <meta property="og:url" content={config.siteUrl + "/contact"} />
                {/* <meta property="article:published_time" content={ghostPost.published_at} />
                <meta property="article:modified_time" content={ghostPost.updated_at} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}
                {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />} */}

                <meta name="twitter:title"
                    content={config.siteTitleMeta}
                />
                <meta name="twitter:description"
                    content={config.siteDescriptionMeta}
                />
                <meta name="twitter:url" content={config.siteUrl + "/contact"} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="more_secure" />
                {/* {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content="MoreSecure" />}

                {settings.twitter && <meta name="twitter:site" content={`https://twitter.com/${settings.twitter.replace(/^@/, ``)}/`} />}
                {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script> */}
            </Helmet>
            <ImageMeta image={coverImage} />
            <NavBar />
            <div className="space-3"></div>
            <div className="space-3"></div>
            <div className="space-3"></div>
            <ContactForm />
            <Footer />
        </>
    );
};

export default Contact;

export const query = graphql`
    query {
        securityImage: file(relativePath: { eq: "security.png" }) {
            childImageSharp {
                fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
